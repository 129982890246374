import React, { useEffect, useState } from 'react'
import Layout from "../components/Layout";
import { backendHost } from "./dashboard";
import { HowItWorks } from "../components/HowItWorks";
import { EnterEmail } from "../components/EnterEmail";
import { Helmet } from "react-helmet";

export default function Dienstverleners ({ data }) {
  const [itadProviders, setItadProviders] = useState([]);
  console.log(`itadProviders`, itadProviders);

  useEffect(() => {
    fetch(`${backendHost}/itad_providers`, {
      method: 'GET'
    }).then(res => res.json())
      .then(data => setItadProviders(data));
  }, []);

  return (
    <Layout selected="dienstverleners">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dientsverleners | Itadise</title>
      </Helmet>
      <h2 className="mt-12 text-center text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
        Vergelijk de beste ITAD service providers
      </h2>
      <div className="p-16"> 
        <div className="flow-root mt-6">
          <ul className="-my-5 divide-y divide-gray-200">
            {itadProviders.map(prvdr => (
              <li key={prvdr.company_name} className="py-4">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {prvdr.company_name}
                    </p>
                    <p className="text-sm text-gray-500 truncate">
                      {prvdr.address}, {prvdr.zip_code} {prvdr.city}
                    </p>
                  </div>
                  <div>
                    <a href="/" className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
                      View
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <HowItWorks />
      <EnterEmail />
    </Layout>
  )
};

export const query = graphql`
  query {
    contentfulBranding {
      logo {
        file {
          url
        }
        title
      }
    }
  }
`